import React, { Component } from "react";
import "./App.scss";

class App extends Component {
  state = {
    how_long: {},
  };
  interval = null;
  componentDidMount() {
    // "2022-07-18 00:00:00"
    const start_date = new Date();

    start_date.setDate(18);
    start_date.setMonth(6);
    start_date.setFullYear(2022);
    start_date.setHours(0);
    start_date.setMinutes(0);
    start_date.setSeconds(0);
    start_date.setMilliseconds(0);

    this.interval = setInterval(() => {
      const date_today = new Date();

      const ms = date_today.getTime() - start_date.getTime();

      let Sekund = Math.floor(ms / 1000);
      let Minut = Math.floor(Sekund / 60);
      let Godzin = Math.floor(Minut / 60);
      let Dni = Math.floor(Godzin / 24);
      let Miesięcy = Math.floor(Dni / 30);
      let Lat = Math.floor(Dni / 365);

      Sekund -= Minut * 60;
      Minut -= Godzin * 60;
      Godzin -= Dni * 24;
      Dni -= Lat * 365;

      if (Sekund < 10) Sekund = `0${Sekund}`;
      if (Minut < 10) Minut = `0${Minut}`;
      if (Godzin < 10) Godzin = `0${Godzin}`;

      this.setState({ how_long: { Lat, Miesięcy, Dni, Godzin, Minut, Sekund } });
    }, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const keys = Object.keys(this.state.how_long);

    return (
      <>
        <div className="czas">
          {keys.map((key, index) => {
            const value = this.state.how_long[key];

            if ((!value && key !== "Sekund") || key === "Miesięcy") return null;

            return (
              <div key={index} className="item">
                <p className="value">{this.state.how_long[key]}</p>
                <p className="label">{key}</p>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default App;
